import React from "react"
import PageTransitionLink from "UI/PageTransition/PageTransitionLink.jsx"
import Layout from "../components/Containers/Layout/Layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />
    <div className="p-2 ">
      <h1>Page not Found</h1>
      <br />
      <p>You just hit a route that doesn&#39;t exist...</p>
      <br />
      <p>
        Go&nbsp;
        <PageTransitionLink to="/">
          Home
        </PageTransitionLink>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
